<template>
  <div>
    <div class="flex-row justify-between items-center header">
      <el-image class="logo" :src="require('@/assets/image/img/logo.png')" @click="toRouter('/')"></el-image>
      <div class="flex-row">
        <a href="http://mall.ldyxyh.com" target="black" class="a-text" >
          <div>商城</div>
        </a>
        <div class="flex-col justify-start input">
          <div class="group">
            <span class="font_1 text_3">学校入驻：</span>
            <span class="text_5">18911993411</span>
          </div>
        </div>
        <div class="flex-col justify-start items-center button text-wrapper">
          <a href="http://company.ldyxyh.com/login" target="targe" class="text_4">机构登录</a>
        </div>
      </div>
    </div>

    <div class="flex-row justify-between items-center group_2">
      <div data-aos="zoom-in">
        <el-image
          class="image_jc"
          fit="cover"
          :src="require('@/assets/image/yhjc/img1.png')"
        />
      </div>
      <div data-aos="zoom-in">
        <el-image
          class="image_jc"
          fit="cover"
          :src="require('@/assets/image/yhjc/img2.png')"
        />
      </div>
      <div data-aos="zoom-in">
        <el-image
          class="image_jc"
          fit="cover"
          :src="require('@/assets/image/yhjc/img3.png')"
        />
      </div>
      <div data-aos="zoom-in">
        <el-image
          class="image_jc"
          fit="cover"
          :src="require('@/assets/image/yhjc/img4.png')"
        />
      </div>
    </div>
    
    <div class="flex-col section space-y-60" data-aos="fade-down">
      <div class="flex-row justify-between">
        <div class="flex-col">
          <div class="flex-row items-center space-x-743">
            <span class="text_6">劳研研学</span>
            <span class="font_2 text_7">艺术体验</span>
          </div>
          <div class="flex-row group_3">
            <span class="font_3" style="margin-right:20px">在劳动中研究</span>
            <span class="font_3">在研究中学习</span>
          </div>
          <span class="self-start text_10">研学课程全覆盖：小学、初中、高中</span>
        </div>
        <span class="self-start font_4 text_8 text_9">传统教育</span>
      </div>
      <div class="flex-col space-y-12">
        <div class="flex-row">
          <div class="flex-row items-center input_2">
            <!-- <span class="font_5 text_12">请输入您的手机号码</span> -->
            <el-input v-model="phone" maxlength="11" placeholder="请输入您的手机号码" class="font_5 text_12"></el-input>
            <a :href="`http://company.ldyxyh.com/login?type=2&phone=${phone}`" target="targe" class="flex-col justify-start items-center button_2 text-wrapper_2">
              <span class="font_5 text_13" >免费入驻</span>
            </a>
          </div>
          <span class="self-start text_11">历史文化</span>
        </div>
        <div class="flex-row justify-between items-center">
          <span class="text_14">现在机构入驻平台，可免费使用，联合地区各所学校推广研学产品</span>
          <div class="flex-col group_4">
            <span class="self-end font_4 text_8">社会实践</span>
            <span class="self-start font_2 text_7">自然科学</span>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-col group_5" data-aos="fade-down">
      <span class="self-center font_6 text_15">专为学校定制研学课程的服务平台</span>
      <div class="flex-row equal-division">
        <div class="flex-col equal-division-item section_2 space-y-19">
          <span class="self-start font_7">小学研学</span>
          <div class="group_7">
            <span class="font_1">
              课程类型丰富，为学校不同阶段学生定制专属课程；家长扫码支付高效便捷。
            </span>
          </div>
        </div>
        <div class="flex-col equal-division-item_2 section_3 space-y-19">
          <span class="self-start font_7">初中研学</span>
          <div class="group_8">
            <span class="font_1">
              记录学生所有研学记录，提供研学结果分析，见证每一位学生的研学成长！
            </span>
          </div>
        </div>
        <div class="flex-col equal-division-item_2 section_4 space-y-19">
          <span class="self-start font_7">初中研学</span>
          <div class="group_8">
            <span class="font_1">
              线上线下专业一体化全套服务流程，帮助机构和学校，以及家长多方协同，省事省心省力。
            </span>
          </div>
        </div>
      </div>
    </div>

    <div data-aos="fade-down">
      <el-image  :src="require('@/assets/image/img/image2.png')"></el-image>
    </div>

    <div class="flex-col group_13 space-y-48" >
      <span class="self-center font_6" data-aos="flip-up">关于劳研研学</span>
      <div class="flex-col space-y-25">
        <img
          class="image_6"
          :src="require('@/assets/image/img/image3.png')"
        />
        <div>
          <p class="font_9">
            劳研研学，是江西壹号农业科技有限公司打造的集教育文化和旅行社职能为一体，专注研学旅行和中小学生综合实践的平台，平台致力于为学校、学生家长提供专业的研学课程，让学生们在“行走的课堂”中增长见识、收获新知。
          </p>
          <p class="font_9">
            江西壹号农业科技有限公司，专注于农业基地、技术研发、研学教育培训等一系列现代化科技领域，是一家开展多方位科技发展的现代化科技管理公司，公司坐落于天下英雄城
            -- 南昌，位于南昌市红谷滩区绿地中央广场双子塔A1栋。
          </p>
          <p class="font_9">
            壹号农业先后获得江西省农产品市场流通协会副会长单位、3A乡村旅游点、AAA级信用企业、中小学生研学实践教育基地等荣誉，并成为江西省第十六届运动会定点食用农产品生产基地。
          </p>
          <p class="font_9">
            2022年，壹号农业被江西农业农村厅评为省级现代农业产业园，更被中国农业农村部评为国家级农业产业强镇。
          </p>
          <p class="font_9">
            壹号农业，以守护中国粮食安全、振兴中国种业为己任，与江西农业大学、南昌大学建立深度校企合作，建立良种研发中心及科研实践基地，持续做强“农业芯”，致力实现“用中国种子种出中国良菜”。
          </p>
          <p class="font_9">
            壹号农业争当乡村振兴的排头兵，通过以大带小、以强带弱、助农富农的“企业+基地+供销合作社+农户”模式，从而实现企业的长远发展，加速成为“立足南昌、辐射江西、走向全国”的全产业链现代农业企业。
          </p>
        </div>
      </div>
    </div>

    <div class="flex-col section_6">
      <span class="self-center text_17">学校合作</span>
      <div class="flex-row justify-center group_15 space-x-8">
        <span class="font_10">使用劳研研学</span>
        <span class="font_10">选择专业研学课程</span>
        <span class="font_10">教育活动化，活动教育化</span>
      </div>
      <div class="flex-row justify-center button_3 section_7 space-x-12 flash-hover">
        <img
          class="image_7"
          :src="require('@/assets/image/img/phone.png')"
        />
        <span class="font_8 text_18">18911993411</span>
      </div>
    </div>


    <div class="flex-row justify-between section_8">
      <div class="flex-col items-start self-center">
        <span class="text text_19">劳研研学</span>
        <el-image class="logo" :src="require('@/assets/image/img/logo2.png')"></el-image>
        <span class="font_11 text_20">全国咨询电话：18911993411</span>
        <span class="font_12 text_21">
          Copyright © 2023 江西壹号农业科技有限公司 All Rights Reserved
          <br />
        </span>
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="blank" class="font_12 text_22">赣ICP备2023009825号-1</a>
      </div>
      <div class="flex-col self-start group_16 space-y-12">
        <div class="flex-row space-x-20">
          <div class="section_9">
            <el-image class="xcx" :src="require('@/assets/image/img/code.png')"></el-image>
          </div>
          <div class="section_9">
            <el-image class="xcx" :src="require('@/assets/image/img/code.png')"></el-image>
          </div>
          <div class="section_9">
            <el-image class="xcx" :src="require('@/assets/image/img/xcx.png')"></el-image>
          </div>
        </div>
        <div class="flex-row group_17 space-x-56">
          <div class="flex-col justify-start items-center text-wrapper_3"><span class="font_11">ios端</span></div>
          <div class="flex-col justify-start"><span class="font_11">Android端</span></div>
          <div class="flex-col justify-start"><span class="font_11">微信小程序</span></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import 'aos/dist/aos.css';
import AOS from 'aos';
export default {
  name: "Home",
  data() {
    return {
      phone: ''
    };
  },
  mounted() {
    this.$nextTick(function () {
      AOS.init({
        easing: 'ease-out-back',
        // duration: 1000
      });
    });
  }
};
</script>

<style>
.a-text {
  color: #0e6efe;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-right: 20px;
  border-bottom: solid 1px #0e6efe;
}
.a-text:hover {
  color: #004fff;
  border-bottom: solid 1px #004fff;
}
.logo {
  width: 172px;
  height: 44px;
}
.header {
  padding: 25px 300px 25px 303px;
  background-color: #ffffff;
  height: 96px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
}

.image_jc {
  width: 300px;
  height: 680px;
}
.group_2 {
  padding: 160px 300px 0;
}
.text_2 {
  color: transparent;
  font-size: 47px;
  font-family: DingTalk;
  line-height: 43px;
  letter-spacing: -4.5px;
}
.input {
  /* padding: 14px 0; */
  background-color: #ffffff;
  border-radius: 23px;
  height: 46px;
  line-height: 46px;
  border: solid 1px #0e6efe;
}
.group {
  margin-left: 15px;
  margin-right: 28px;
}
.font_1 {
  font-size: 18px;
  font-family: Microsoft YaHei;
  line-height: 30px;
  color: #ffffff;
}
.text_3 {
  color: #0e6efe !important;
  line-height: 17px;
}
.text_5 {
  color: #0e6efe;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 700;
  line-height: 14px;
}
.button {
  margin-left: 23px;
}
.text-wrapper {
  background-color: #0e6efe;
  border-radius: 23px;
  width: 160px;
  height: 46px;
  line-height: 46px;
}
.text_4 {
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
}

/* 二 */
.section {
  padding: 137px 300px 122px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63c10724ae096200115a07f0/16957776001545352294.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 96px;
}
.space-y-60 > *:not(:first-child) {
  margin-top: 60px;
}
.space-x-743 > *:not(:first-child) {
  margin-left: 743px;
}
.text_6 {
  color: #242347;
  font-size: 32px;
  font-family: PingFang;
  line-height: 31px;
}
.font_2 {
  font-size: 12px;
  font-family: PingFang;
  line-height: 13px;
  color: #ffffff;
}
.text_7 {
  transform: rotate(-3deg);
}
.group_3 {
  margin-top: 30px;
}
.font_3 {
  font-size: 54px;
  font-family: PingFang;
  line-height: 51px;
  color: #242347;
}
.text_10 {
  margin-top: 41px;
  color: #3172ff;
  font-size: 24px;
  font-family: PingFang;
  line-height: 23px;
}
.font_4 {
  font-size: 12px;
  font-family: PingFang;
  line-height: 15px;
  color: #ffffff;
}
.text_8 {
  transform: rotate(5deg);
}
.text_9 {
  margin-right: 55px;
  margin-top: 121px;
}
.input_2 {
  padding-left: 24px;
  background-color: #ffffff;
  border-radius: 10px;
  height: 66px;
}
.font_5 {
  font-size: 20px;
  font-family: PingFang;
  line-height: 19px;
}
.text_12 {
  width: 368px;
  color: #bfcbf3;
}
 .el-input__inner {
  border: none;
}

.button_2 {
  flex-shrink: 0;
  /* margin-left: 189px; */
}
.text-wrapper_2 {
  padding: 24px 0;
  background-color: #0e6efe;
  border-radius: 0px 10px 10px 0px;
  width: 180px;
  height: 66px;
}
.text_13 {
  color: #ffffff;
}
.text_11 {
  margin-left: 131px;
  margin-top: 15px;
  color: #ffffff;
  font-size: 14px;
  font-family: PingFang;
  line-height: 14px;
}
.text_14 {
  color: #597792;
  font-size: 18px;
  font-family: PingFang;
  line-height: 19px;
}
.group_4 {
  margin-right: 42px;
  width: 244px;
}
.space-y-12 > *:not(:first-child) {
  margin-top: 12px;
}

/* 三 */
.font_1 {
  font-size: 18px;
  font-family: Microsoft YaHei;
  line-height: 30px;
  color: #ffffff;
}
.group_5 {
  padding: 100px 300px 80px;
}
.font_6 {
  font-size: 40px;
  font-family: Microsoft YaHei;
  line-height: 40px;
  color: #242347;
}
.text_15 {
  line-height: 41px;
}
.equal-division {
  margin-top: 47px;
}
.equal-division-item {
  flex: 1 1 416px;
}
.section_2 {
  padding: 258px 24px 35px;
  border-radius: 10px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63c10724ae096200115a07f0/16957776001596579715.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 400px;
}
.font_7 {
  font-size: 28px;
  font-family: Microsoft YaHei;
  line-height: 28px;
  font-weight: 700;
  color: #ffffff;
}
.group_7 {
  line-height: 30px;
}
.equal-division-item_2 {
  flex: 1 1 416px;
  margin-left: 36px;
}
.section_3 {
  padding: 258px 24px 35px;
  border-radius: 10px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63c10724ae096200115a07f0/16957776001644076588.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 400px;
}
.group_8 {
  line-height: 30px;
}
.section_4 {
  padding: 258px 24px 35px;
  border-radius: 10px;
  background-image: url('~@/assets/image/img/item3.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 400px;

  /* border-radius: 10px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63c10724ae096200115a07f0/16957776001685552223.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 400px; */
}
.group_6 {
  padding: 258px 24px 35px;
  border-radius: 10px;
}
.space-y-19 > *:not(:first-child) {
  margin-top: 19px;
}
.group_9 {
  line-height: 30px;
}

/* 四 */
.font_6 {
  font-size: 40px;
  font-family: Microsoft YaHei;
  line-height: 40px;
  color: #242347;
}
.group_13 {
  margin-top: 80px;
  padding-left: 298px;
  padding-right: 294px;
  padding-bottom: 30px;
}
.space-y-48 > *:not(:first-child) {
  margin-top: 48px;
}
.space-y-25 > *:not(:first-child) {
  margin-top: 25px;
}
.image_6 {
  margin-right: 6px;
  border-radius: 20px;
  width: 1320px;
  height: 308px;
}
.font_9 {
  font-size: 18px;
  font-family: Microsoft YaHei;
  line-height: 42px;
  color: #000000;
  text-indent: 2.2em;
}

/* 五 */
.font_8 {
  font-size: 20px;
  font-family: Microsoft YaHei;
  line-height: 36px;
  color: #323232;
}
.section_6 {
  padding: 40px 0;
  background-color: #f8fbff;
  background-image: url('~@/assets/image/img/image4.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.text_17 {
  color: #242347;
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: 2px;
}
.group_15 {
  margin-top: 19px;
}
.space-x-8 > *:not(:first-child) {
  margin-left: 8px;
}
.font_10 {
  font-size: 20px;
  font-family: Microsoft YaHei;
  letter-spacing: 2px;
  line-height: 19px;
  color: #242347;
}
.button_3 {
  align-self: center;
  margin-top: 30px;
}
.section_7 {
  padding: 18px 28px;
  background-color: #0e6efe;
  border-radius: 8px;
  width: 240px;
}
.space-x-12 > *:not(:first-child) {
  margin-left: 12px;
}
.image_7 {
  width: 20px;
  height: 18px;
}
.text_18 {
  margin-right: 16px;
  color: #fafcff;
  font-weight: 700;
  line-height: 16px;
}

/* 六 */
.text {
  background-image: linear-gradient(0deg, #004fff 0%, #0086ff 100%);
  -webkit-background-clip: text;
}
.section_8 {
  padding: 48px 299px;
  background-color: #18243a;
}
.font_11 {
  font-size: 16px;
  font-family: Microsoft YaHei;
  line-height: 15px;
  color: #999999;
}
.text_20 {
  margin-top: 32px;
  color: #ffffff;
}
.font_12 {
  font-size: 16px;
  font-family: Microsoft YaHei;
  line-height: 22.5px;
  color: #999999;
}
.text_21 {
  margin-top: 28px;
}
.text_22 {
  margin-top: 14px;
}
.group_16 {
  margin-top: 17px;
}
.space-y-12 > *:not(:first-child) {
  margin-top: 12px;
}
.space-x-20 > *:not(:first-child) {
  margin-left: 40px;
}
.section_9 {
  background-color: #ffffff;
  width: 120px;
  height: 120px;
}
.group_17 {
  padding: 0 18px;
}
.space-x-56 > *:not(:first-child) {
  margin-left: 75px;
}
.text-wrapper_3 {
  width: 85px;
}

.xcx {
  transition: .5s;
  -webkit-transition: .5s;
}
.xcx:hover {
  transform: scale(1.5);
  transition: .5s;
  -webkit-transition: .5s;
}
</style>